<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <div class="max-w-6xl mx-auto px-4 sm:px-6">
        <div class="pt-32 pb-12 md:pt-40 md:pb-20">
          <div class="max-w-3xl mx-auto text-center">
            <!-- 404 content -->
            <h1 class="h1 mb-4" data-aos="zoom-y-out">Něco se pokazilo! 🙈</h1>
            <div class="mt-8">
              <router-link to="/" class="btn text-white bg-blue-800 hover:bg-blue-700">Přejít na domovskou stránku</router-link>
            </div>
            <div class="mt-2">
              <router-link to="/kontakt" class="btn text-white bg-gray-600 hover:bg-gray-500">Napište nám</router-link>
            </div>
          </div>
        </div>
      </div>
    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
import Footer from './../partials/Footer.vue'

export default {
  name: 'Features',
  components: {
    Header,
    Footer,
  },
}
</script>
